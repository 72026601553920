import { configureStore } from "@reduxjs/toolkit";
import obituariesReducer from "./reducers/getObituaires";
import obituaryReducer from "./reducers/singleObituary";

export const store = configureStore({
  reducer: {
    obituariesList: obituariesReducer,
    singleObituary: obituaryReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
